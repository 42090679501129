// Header
// ======

.global-header {
  @include z-index('global-header');

  border-radius: 0;
  background: $color-background-alt;
}

.page-header {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.site-logo {
  max-height: 2.5rem;
}

[aria-labelledby='logout'] {
  .slds-dropdown__item > a[aria-disabled='true'] {
    font-weight: 500;
    font-size: 1rem;
    color: $color-text-default;
  }
}
